import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageLink from "../components/pageLink"
import Tagline from "../components/tagline"
import SEO from "../components/seo"

const TutorialsPage = ({ data }) => (
  <Layout>
    <SEO title="Reviews. Information. Opinions. Tutorials." />
    <h2>Tutorials</h2>
    <Tagline />
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <PageLink
        slug={node.fields.slug}
        title={node.frontmatter.title}
        description={node.frontmatter.description}
        date={node.frontmatter.date}
        category={node.frontmatter.category}
      />
    ))}
  </Layout>
)

export default TutorialsPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "tutorial" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            description
            title
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

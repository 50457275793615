import React from "react"
import { Link } from "gatsby"

export default () => (
  <p>
    Our goal is to provide quality, helpful information on a variety of topics.
    All of our content is based on first-hand personal experience.{" "}
    <Link to="/about">About AJ</Link>.
  </p>
)

import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

export const Page = styled.div`
  padding: 0 0 1.3rem 0;
`

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 100;
  color: #1976d2;
  margin: 0 0.5rem 0 0;
`

export const Description = styled.div`
  padding: 0.3rem 0;
`

export const Date = styled.div`
  font-size: 0.85rem;
  margin: 0 0.5rem 0 0;
  float: left;
  color: #444;
`

export const Category = styled.div`
  font-size: 0.85rem;
  color: #444;
`

export const capitalize = css`
  text-transform: capitalize;
`

const PageLink = ({ title, description, slug, date, category }) => {
  return (
    <Page>
      <Link to={slug}>
        <Title>{title}</Title>
      </Link>
      <Description>{description}</Description>
      <Date>{date}</Date>
      <Category css={capitalize}>{category}</Category>
    </Page>
  )
}

export default PageLink
